import { css } from "@emotion/react"
import React from "react"
import { flexBoxCss } from "../../../styles"

interface AddToCartButtonProps {
  style?: React.CSSProperties
  disabled?: boolean
  onClick: () => void
}

const container = css`
  ${flexBoxCss("v", "center", "center")}
  width: 72px;
  border: 1px solid black;
  background-color: inherit;
  padding-top: 2px;
  cursor: pointer;
  align-self: stretch;
  min-width: 70px;

  &:focus {
    outline: none;
  }

  span {
    font-size: 12px;
  }

  img {
    display: none;
  }

  @media (max-width: 1024px) {
    min-width: auto;
    width: 36px;
    padding: 0px;
    span {
      display: none;
    }
    img {
      display: unset;
    }
  }
`

const disabledContainer = css`
  ${container}
  border: 1px solid rgba(0,0,0,0.2);
  cursor: inherit;
  img {
    opacity: 0.2;
  }
`

const AddToCartButton = ({ style, onClick, disabled }: AddToCartButtonProps) => {
  return (
    <button
      onClick={onClick}
      style={style}
      css={disabled ? disabledContainer : container}
      disabled={disabled}
    >
      <span>장바구니</span>
      <img src={require("../../../images/add-to-cart.svg")} />
    </button>
  )
}

export default AddToCartButton
