import { css } from "@emotion/react";

export function flexBoxCss(direction: "v"|"h", justifyContent?: string, alignItems?: string) {
  return css`
    display: flex;
    flex-direction: ${direction==="v" ? "column" : "row"};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `
}

export const hBox = css`
  display: flex;
  flex-direction: row;
`

export const vBox = css`
  display: flex;
  flex-direction: column;
`

export const hBoxAlCe = css`
  ${hBox}
  align-items: center;
`