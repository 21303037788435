import { css } from "@emotion/react"
import React, { useContext, useState } from "react"
import { ProductStatus } from "../../../API"
import { globalContext } from "../../../contexts/GlobalContext"
import { numberWithCommas } from "../../../functions"
import { flexBoxCss } from "../../../styles"
import { MarginStyle, Product } from "../../../types"
import { CLOUD_FRONT_URI } from "../../../values"
import AddToCartButton from "../../atom/AddToCartButton"
import CountButton from "../../atom/CountButton"
import "./style.scss"

interface ProductItemProps {
  style?: MarginStyle
  product: Product
  isForConfirmModal?: boolean
}

const container = css`
  ${flexBoxCss("v")}
  width: 230px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    background-color: transparent;
    width: calc(100vw / 3);
    padding: 10px;
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 600px) {
    width: 50vw;
  }
`

const confirmContainer = css`
  ${flexBoxCss("v")}
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;
`

const confirmImageContainer = css`
  ${flexBoxCss("v", "center", "center")}
  position: relative;
  width: 100%;
  height: 215px;
  img {
    width: 100%;
    height: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    padding-bottom: 0;
    border-radius: 10px;
    overflow: hidden;
  }
`

const imageContainer = css`
  ${flexBoxCss("v", "center", "center")}
  position: relative;
  width: 100%;
  height: 215px;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  @media (max-width: 1024px) {
    height: calc(100vw / 3 - 30px);
  }
  @media (max-width: 600px) {
    height: calc(50vw - 20px);
  }
`

const saleTextContainer = css`
  ${flexBoxCss("h", "center", "center")}
  position: absolute;
  width: 99.2%;
  height: 28px;
  bottom: 0px;
  background-color: rgba(77, 102, 118, 0.6);
  padding: 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    font-size: 13px;
    font-weight: 300;
    color: white;
    margin-top: 2px;
  }
`

const saleTextContainerForConfirmModal = css`
  ${flexBoxCss("h", "center", "center")}
  position: absolute;
  width: 230px;
  height: 28px;

  top: 278px;

  background-color: rgba(77, 102, 118, 0.6);
  padding: 0px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  span {
    font-size: 13px;
    font-weight: 300;
    color: white;
    margin-top: 2px;
  }
`

const ProductItem = ({ style, product, isForConfirmModal }: ProductItemProps) => {
  const { deliveryReducer, addConfirmModalHook } = useContext(globalContext)!
  const [count, setCount] = useState<number>(1)

  function handleCartButtonPress() {
    deliveryReducer.addCartItem(product, count)
    setCount(1)
    addConfirmModalHook.showModal({
      product: product,
      count: count,
      id: "0",
    })
  }

  function handleIncreaseButtonPress() {
    setCount(count + 1)
  }

  function handleDecreaseButtonPress() {
    if (count > 0) setCount(count - 1)
  }

  function isEventActive(product: Product) {
    if (product.event) {
      if (
        product.event.isActive &&
        new Date(product.event.startDate) <= new Date() &&
        new Date(product.event.endDate) >= new Date()
      ) {
        return true
      }
    }

    return false
  }

  return (
    <div
      css={isForConfirmModal ? confirmContainer : container}
      style={{
        ...style,
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: product?.status === ProductStatus.INACTIVE ? undefined : "none",
          zIndex: 3,
          opacity: 1,
          fontFamily: "Noto Sans KR",
          fontWeight: 600,
        }}
      >
        입고대기중
      </span>
      <div
        style={{
          opacity: product?.status === ProductStatus.INACTIVE ? 0.4 : 1,
        }}
      >
        <div css={isForConfirmModal ? confirmContainer : imageContainer}>
          <img src={`${CLOUD_FRONT_URI}/${product.uri}`} />
          {isEventActive(product) && (
            <div css={isForConfirmModal ? saleTextContainerForConfirmModal : saleTextContainer}>
              <span>
                {product.eventPercentage ? product.eventPercentage + "% 할인중" : "기간제 판매상품"}
              </span>
            </div>
          )}
        </div>
        <div className="product-information-container">
          <span className="product-name-text">{product.name}</span>
          <div className="h-box" style={{ alignItems: "flex-end" }}>
            {isEventActive(product) && product.eventPercentage && product.eventPrice ? (
              <div className="product-price-text-container">
                <span className="product-price-text">{numberWithCommas(product.eventPrice)}</span>
                <span className="product-price-unit">원</span>
                <span className="product-price-gray">{numberWithCommas(product.price)}원</span>
              </div>
            ) : (
              <div className="product-price-text-container">
                <span className="product-price-text">{numberWithCommas(product.price)}</span>
                <span className="product-price-unit">원</span>
              </div>
            )}
          </div>
        </div>

        {!isForConfirmModal && (
          <div className="add-to-cart-container">
            <CountButton
              count={count}
              onClickIncrease={handleIncreaseButtonPress}
              onClickDecrease={handleDecreaseButtonPress}
              disabled={product?.status === ProductStatus.INACTIVE}
            />
            <AddToCartButton
              onClick={handleCartButtonPress}
              disabled={count == 0 || product.status === ProductStatus.INACTIVE}
            />
            {/* <button className="add-to-cart-button" disabled={count == 0} onClick={handleCartButtonPress}>
            <span className="add-to-cart-text">장바구니</span>
            <img
              className="mobile-add-to-cart-button-image"
              src={require("../../../images/add-to-cart.svg")}
            />
          </button> */}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductItem
